<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Customer Deposit</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Territory</label>
        <multiselect
          class="bg-dark"
          v-model="create.territory"
          :options="optionTerritory"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="true"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{
                dt.option.code + " " + dt.option.name
              }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{
                dt.option.code + " " + dt.option.name
              }}</span>
            </div>
          </template>
        </multiselect>
      </div>
      <div class="vx-col w-full">
        <label class="vs-input--label">Posting Date</label>
        <datepicker
          class="w-full bg-dark"
          v-validate="'required'"
          :inline="false"
          name="Date Deposit"
          v-model="date_deposit"
          placeholder="Date Deposit"
          disabled
        ></datepicker>
        <!-- disabled-dates="{from: new Date()}" -->
      </div>
      <div class="vx-col w-full">
        <label class="vs-input--label">Cash Deposit Slip No</label>
        <vs-input
          class="w-full input-readonly"
          v-model="create.deposit_number"
          readonly
        />
      </div>

      <!-- <div class="vx-col w-full">
        <label class="vs-input--label">Collection Date</label>
        <datepicker
          class="w-full bg-dark"
          v-validate="'required'"
          :inline="false"
          name="Invoice Date"
          v-model="date_clearing"
          placeholder="Date Clearing"
          :disabled-dates="{from: new Date()}"
          disabled
        ></datepicker>
      </div> -->

      <div class="vx-col w-full">
        <label class="vs-input--label">Deposit Value</label>
        <vs-input
          class="w-full input-readonly"
          v-model="create.amount"
          @keyup="create.amount = formatPrice(create.amount.toString())"
          readonly
        />
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Ref Code</label>
        <vs-input
          class="w-full input-readonly"
          v-model="create.ref_code"
          readonly
        />
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Method Management</label>
        <multiselect
          class="selectExample"
          v-model="create.method"
          :options="optionMethod"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          :disabled="true"
          placeholder=" Type to search"
        >
        </multiselect>
      </div>

      <div class="vx-col w-full" v-if="!hideForm">
        <label class="vs-input--label">Customer</label>
        <multiselect
          class="selectExample bg-dark"
          v-model="create.customer"
          :options="optionCustomer"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="code_name"
          @search-change="getOptionCustomer"
          :disabled="true"
        >
        </multiselect>
      </div>

      <div class="vx-col w-full" v-if="!hideForm">
        <label class="vs-input--label">{{ this.create.method }} Date</label>
        <datepicker
          class="w-full bg-dark"
          v-validate="'required'"
          :inline="false"
          name="Date"
          v-model="date_giro"
          :placeholder="'Date ' + create.method"
          :disabled-dates="{ from: new Date() }"
          disabled
        ></datepicker>
      </div>

      <div class="vx-col w-full" v-if="!hideForm">
        <label class="vs-input--label">Bank</label>
        <multiselect
          class="selectExample bg-dark"
          v-model="bank"
          :options="optionBank"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="true"
        >
        </multiselect>
      </div>

      <div class="vx-col w-full" v-if="!hideForm">
        <label class="vs-input--label"
          >Number Of {{ this.create.method }}</label
        >
        <vs-input
          class="w-full input-readonly"
          v-model="create.number_of"
          readonly
        />
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Operating Unit Bank</label>
        <multiselect
          class="selectExample input-readonly"
          v-model="create.operatingUnitBank"
          :options="optionOperatingUnitBank"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="ID"
          :disabled="true"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{
                dt.option.BankName +
                " " +
                dt.option.AccountName +
                " " +
                dt.option.AccountNumber
              }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{
                dt.option.BankName +
                " " +
                dt.option.AccountName +
                " " +
                dt.option.AccountNumber
              }}</span>
            </div>
          </template>
        </multiselect>
      </div>

      <div class="vx-col w-full">
        <label class="vs-input--label">Note</label>
        <vs-textarea
          class="input-readonly bg-dark"
          v-model="create.note"
          readonly
        />
      </div>
      <vs-divider style="width: 100%; margin-left: 2%"
        >List Attachment</vs-divider
      >
      <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <table class="vs-table vs-table--tbody-table">
          <template v-for="tr in fileAttachment">
            <tr
              class="tr-values vs-table--tr tr-table-state-null selected"
              v-bind:key="tr.name"
            >
              <td class="td vs-table--td">{{ tr.name }}</td>
              <td class="td vs-table--td">
                <vx-tooltip text="Show" v-if="tr.path != ''">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                    @click.stop="downloadFileAwsS3(tr.path)"
                  />
                </vx-tooltip>
              </td>
              <td class="td vs-table--td">
                <template>
                  <vx-tooltip text="Delete">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-trash"
                      @click.stop="handleDeleteAttachment(i, tr.id)"
                    />
                  </vx-tooltip>
                </template>
              </td>
            </tr>
          </template>
        </table>
      </div>
      <vs-divider style="width: 100%"><b></b></vs-divider>
      <div
        class="vx-col w-full"
        v-if="
          selected.CashBankManagementLine.length > 0 &&
          selected.CashBankManagementLine[0].PaymentID !== 0
        "
      >
        <vs-table stripe border description :sst="true" :data="payment">
          <template slot="thead">
            <vs-th>Payment Date</vs-th>
            <vs-th>Payment Number</vs-th>
            <vs-th>Invoice</vs-th>
            <vs-th>Payment Value</vs-th>
            <vs-th>Payment Method</vs-th>
            <vs-th></vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr v-bind:key="indextr" v-for="(tr, indextr) in data">
              <vs-td>{{ dateFormat(tr.DatePayment) }}</vs-td>
              <vs-td>{{ tr.ReferenceCode }}</vs-td>
              <vs-td>
                <template v-for="subTr in tr.Lines">
                  <div v-bind:key="subTr.ID">
                    {{ subTr.InvoiceCode }} ({{
                      formatPrice(subTr.InvoicePaymentAmount)
                    }})
                    <vs-divider style="width: 100%"></vs-divider>
                  </div>
                </template>
              </vs-td>
              <vs-td>{{ tr.PaymentMethod }}</vs-td>
              <vs-td>{{ formatPrice(tr.Amount) }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  components: {
    Datepicker,
  },
  created() {},
  data() {
    return this.initialState();
  },
  props: {
    selected: Object,
  },
  methods: {
    initialState() {
      return {
        create: {
          id: null,
          customer: null,
          amount: 0,
          ref_code: "",
          deposit_number: "",
          note: "",
          territory: null,
          date_clearing: null,
          method: null,
          status: 2,
          date_deposit: null,
          operatingUnitBank: null,
          number_of: "",
        },
        payment: [],
        checked: [],
        dataChecked: [],
        hideForm: true,
        date_giro: null,
        date_deposit: null,
        date_clearing: null,
        bank: null,
        optionOperatingUnitBank: [],
        optionTerritory: [],
        optionCustomer: [],
        optionBank: [],
        optionMethod: ["Cash", "Giro", "Cheque", "Transfer"],
        fileAttachment: [],
      };
    },
    formatPrice(angka, prefix = "") {
      return angka
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    checkForm() {
      if (this.create.operatingUnitBank == null) {
        this.$vs.notify({
          title: "Error",
          text: "please select operating unit bank first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false;
      }
      if (this.create.amount == 0) {
        this.$vs.notify({
          title: "Error",
          text: "value deposit must be greater than 0",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false;
      }
      if (this.create.deposit_number == "") {
        this.$vs.notify({
          title: "Error",
          text: "please fill deposit number",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false;
      }
      if (this.create.territory == null) {
        this.$vs.notify({
          title: "Error",
          text: "please choose territory",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false;
      }
      if (this.date_clearing == null) {
        this.$vs.notify({
          title: "Error",
          text: "please fill Collection Date",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false;
      }
      if (this.create.method == null) {
        this.$vs.notify({
          title: "Error",
          text: "method can't be null",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false;
      }
      if (this.date_deposit == null) {
        this.$vs.notify({
          title: "Error",
          text: "please fill date deposit",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return false;
      }
      return true;
    },
    check(val, index) {
      if (this.checked.includes(val)) {
        this.checked.splice(this.checked.indexOf(val), 1);
        this.dataChecked = this.dataChecked.filter((v) => {
          return v.ID != val;
        });
      } else {
        console.log(val, index);
        this.checked.push(val);
        this.dataChecked.push(this.payment[index]);
      }
    },
    doFailed() {
      this.$vs.loading();
      const params = {
        status: 4,
        id: [this.selected.ID],
        // "notes": this.notes
      };

      this.$http
        .post("/api/v1/cash-bank/change-status", params)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();

            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "error catch",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log(e);
        });
    },
    handleSubmit() {
      console.log(this.delta);
      if (this.delta > 0) {
        const params = {
          payment_id: this.checked,
          id: this.selected.ID,
        };
        this.$vs.loading();
        this.$http
          .post("/api/v1/cash-bank/mapping", params)
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.handleClose();
              this.$vs.notify({
                title: "Success",
                text: "",
                color: "success",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            } else {
              this.$vs.notify({
                title: "Error",
                text: "Failed to create",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            }
          })
          .catch((e) => {
            this.$vs.notify({
              title: "Error",
              text: "Failed to create",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          });
      }
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    getMapping(id) {
      this.$vs.loading();

      this.$http.get(`/api/v1/cash-bank/line-mapping/${id}`).then((r) => {
        this.$vs.loading.close();
        if (r.code == 200) {
          this.payment = r.data.payment;
          this.checked = [];
          r.data.payment.map((subr) => {
            if (subr.ReferenceCode == this.selected.DepositNumber) {
              this.checked.push(subr.ID);
            }
          });
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to load payment",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
        console.log(r);
      });
    },
    getBankForm(ID) {
      this.$http
        .get("/api/v1/cash-bank/form-bank/" + ID)
        .then((r) => {
          // this.optionTerritory = r.data.territory
          // this.optionTerritory = r.data.territories
          // this.optionBank = r.data.bank
          // console.log(r)
          this.optionOperatingUnitBank = r.data.operatingUnitBank;
          // this.optionOperatingUnitBank = r.data.operatingUnitBank
          r.data.operatingUnitBank.map((r) => {
            if (
              r.BankID == this.selected.BankID &&
              r.BranchName == this.selected.BankBranchName &&
              r.AccountNumber == this.selected.AccountNumber
            ) {
              this.create.operatingUnitBank = r;
              console.log(this.create.operatingUnitBank);
            }
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // /cash-bank/get-mapping
    getGeneralForm() {
      this.$vs.loading();

      this.$http
        .get("/api/v1/cash-bank/form-general")
        .then((r) => {
          // this.optionTerritory = r.data.territory
          this.optionTerritory = r.data.territories;
          r.data.territories.map((r) => {
            if (r.ID == this.selected.TerritoryID) {
              this.create.territory = r;
            }
          });
          this.create.ref_code = this.selected.ReferenceCode;
          this.create.id = this.selected.ID;
          this.create.method = this.optionMethod[this.selected.Method - 1];
          this.optionBank = r.data.bank;
          r.data.bank.map((r) => {
            if (r.ID == this.selected.GiroBankID) {
              this.bank = r;
            }
          });

          this.getBankForm(this.selected.TerritoryID);
          // this.optionOperatingUnitBank = r.data.operatingUnitBank
          // r.data.operatingUnitBank.map(r => {
          //   if (r.BankID == this.selected.BankID && r.BranchName == this.selected.BankBranchName && r.AccountNumber == this.selected.AccountNumber) {
          //     this.create.operatingUnitBank = r
          //   }
          // })

          this.create.amount = this.formatPrice(this.selected.DepositValue);
          this.date_clearing = this.selected.DatePayment;
          this.date_giro = this.selected.GiroDate;
          this.date_deposit = this.selected.Date;
          this.create.number_of = this.selected.GiroNumber;
          this.create.deposit_number = this.selected.DepositNumber;
          this.create.note = this.selected.Note;
          this.getOptionCustomer(this.selected.PersonName);
          // this.create.customer = this.selected.DepositNumber
          // this.create.bank = this.selected.GiroBankID
          this.$vs.loading.close();
          this.getMapping(this.selected.ID);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    mappingAttachment() {
      this.selected.CashBankManagementAttachment.map((v) => {
        this.fileAttachment.push({
          id: v.ID,
          name: v.NameFile,
          path: v.PathFile,
        });
      });
    },
    handleDeleteAttachment(index, id) {
      this.deleteFile(id);
      this.fileAttachment = this.fileAttachment.filter((item, i) => i != index);
      // console.log(this.table.file);
    },
    deleteFile(id) {
      this.$vs.loading();
      var form = new FormData();
      form.append("id", id);
      this.$http
        .post("/api/v1/cash-bank/delete-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getOptionCustomer(query) {
      if (this.create.territory == null) {
        this.$vs.notify({
          color: "danger",
          title: "Required",
          text: "Territory is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }
      if (query.length <= 2) {
        return;
      }
      let param = {
        territory_id: this.create.territory.id
          ? this.create.territory.id
          : this.create.territory.ID,
        search: query,
      };

      this.$http
        .get("/api/v1/cash-bank/customer", {
          params: param,
        })
        .then((r) => {
          this.optionCustomer = r.data.customer;
          if (r.name == this.selected.PersonName) {
            this.create.customer = r;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    console.log(this.selected);
    console.log("mounted");
    this.getGeneralForm();
    this.mappingAttachment();
  },
  computed: {
    invoice_value_selected: function () {
      console.log("compute");
      return this.formatPrice(
        this.dataChecked.reduce(
          (acc, value) =>
            acc +
            parseInt(value.Amount.toString().replace(/,/g, "").toString()),
          0
        )
      );
    },
    deposit_value: function () {
      return this.formatPrice(this.selected.DepositValue);
    },
    delta_invoice: function () {
      return this.formatPrice(
        this.selected.DepositValue -
          this.dataChecked.reduce(
            (acc, value) =>
              acc +
              parseInt(value.Amount.toString().replace(/,/g, "").toString()),
            0
          )
      );
    },
    delta: function () {
      return (
        this.selected.DepositValue -
        this.dataChecked.reduce(
          (acc, value) =>
            acc +
            parseInt(value.Amount.toString().replace(/,/g, "").toString()),
          0
        )
      );
    },
  },
  watch: {
    "create.method"() {
      if (this.create.method == "Cash") {
        this.hideForm = true;
      } else {
        this.hideForm = false;
      }
    },
    "selected.ID"() {
      this.$nextTick(() => {
        console.log(this.selected);
        this.getGeneralForm();
      });
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>
