<template>
  <div class="vx-row mb-12">
    <div
      v-bind:class="[
        detail ? headerDetailShow + ' vertical-divider' : '',
        detailHide,
      ]"
    >
      <data-table
        :responseData="responseData"
        :propsParams="params"
        :header="header"
        @reloadDataFromChild="reloadData"
      >
        <template slot="thead">
          <th width="7%">Action</th>
        </template>
        <template slot="tbody">
          <vs-tr
            :key="indextr"
            v-for="(tr, indextr) in responseData.cashBank"
            :class="tr.class"
          >
            <vs-td class="whitespace-no-wrap">
              <template>
                <div class="mt-4 flex justify-between">
                  <vx-tooltip text="Show" class="mr-4">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-eye"
                      v-on:click.stop="handleShow(tr)"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Export" class="mr-4">
                    <vs-button
                      type="line"
                      icon-pack="feather"
                      icon="icon-file"
                      color="success"
                      v-on:click.stop="generateExcel(tr)"
                    />
                  </vx-tooltip>
                  <!-- <vx-tooltip text="back to mapping" class="mr-4">
                  <vs-button
                    color="red"
                    type="line"
                    icon-pack="feather"
                    icon="icon-rewind"
                    v-on:click.stop="
                      backToMapping(tr)"
                  />
                </vx-tooltip> -->
                </div>
              </template>
            </vs-td>

            <vs-td>
              Code : {{ tr.PersonCode }} Name : {{ tr.PersonName }}
            </vs-td>
            <vs-td> {{ priceFormat(tr.DepositValue) }}<br /> </vs-td>
          </vs-tr>
        </template>
      </data-table>
      <!-- Approve Prompt -->
      <vs-prompt
        title="Confirmation"
        color="primary"
        @accept="approve"
        @cancel="closeDetail"
        @close="closeDetail"
        :buttons-hidden="false"
        :active.sync="approvalPrompt"
      >
        <div class="con-exemple-prompt">
          Cash Deposit
          <br />
          Are you sure to release draft <b>{{ this.selectedData.Code }}</b> ?
          <br />
          <br />
          <vs-textarea v-model="notes" />
        </div>
      </vs-prompt>
    </div>
    <!-- form -->
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[detail ? detailShow + ' ' : '', detailHide]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>
          <vs-col
            vs-offset="0"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="1"
          >
            <!-- <vs-button
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
            ></vs-button> -->
          </vs-col>
          <waiting-div
            v-if="renderComponent"
            @close="handleClose"
            :selected="selectedData"
          />
        </vs-row>
      </div>
    </transition>
  </div>
</template>
<script>
import waiting from "./waiting.vue";
import { jsPDF } from "jspdf";
import * as XLSX from "xlsx";
import moment from "moment";
// import { dataPaymentCollection } from "../../../../../services/api/invoice";
export default {
  components: {
    "waiting-div": waiting,
  },
  props: {
    selected: Object,
    option: Object,
    depositDate: Object,
    collectionDate: Object,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "person_id",
      },
      header: [
        {
          text: "Customer",
          value: "person_code",
          // width: '5%'
        },
        {
          text: "Deposit Value",
          value: "deposit_value",
          sortable: false,
        },
      ],
      methods: ["", "Cash", "Giro", "Cheque", "Transfer"],
      responseData: {},
      detailShow: "vx-col md:w-2/3 w-full mb-base",
      headerDetailShow: "vx-col md:w-1/3 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      notes: "",
      approveOrReject: false,
      approvalPrompt: false,
      rejectPrompt: false,
      selectedData: {},
      renderComponent: true,
    };
  },
  computed: {},
  watch: {
    depositDate() {
      this.reloadData(this.params);
    },
    collectionDate() {
      this.reloadData(this.params);
    },
  },
  mounted() {},
  methods: {
    handleApprove(selected) {
      this.selectedData.class = "";
      this.approveOrReject = 1;
      selected.class = "highlight";
      this.selectedData = selected;
      this.rejectPrompt = false;
      this.approvalPrompt = true;
      console.log(this.selectedData);
    },
    handleReject(selected) {
      this.selectedData.class = "";
      this.approveOrReject = 0;
      selected.class = "highlight";
      this.selectedData = selected;
      this.approvalPrompt = false;
      this.rejectPrompt = true;
    },

    approve() {
      this.doSubmit();
      // console.log(this.selectedData)
    },
    forceRender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    backToMapping(tr) {
      this.$vs.loading();
      const params = {
        status: 2,
        id: [tr.ID],
        // "notes": this.notes
      };

      this.$http
        .post("/api/v1/cash-bank/change-status", params)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();

            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "error catch",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log(e);
        });
    },
    closeDetail() {
      this.activePrompt2 = false;
      this.detail = false;
      this.selectedData.class = "";
      this.selectedData = {
        ID: 0,
      };
      this.reloadData(this.params);
    },
    dataTable(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/cash-bank/data-table/customer-deposit", {
            params: {
              status: 3,
              type: 2,
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.sort,
              sort: params.order,
              start_collect_date: this.collectionDate.startDate
                ? moment(this.collectionDate.startDate).format("YYYY-MM-DD")
                : null,
              end_collect_date: this.collectionDate.endDate
                ? moment(this.collectionDate.endDate).format("YYYY-MM-DD")
                : null,
              start_deposit_date: this.depositDate.startDate
                ? moment(this.depositDate.startDate).format("YYYY-MM-DD")
                : null,
              end_deposit_date: this.depositDate.endDate
                ? moment(this.depositDate.endDate).format("YYYY-MM-DD")
                : null,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getBase64Image(url) {
      const img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL("image/png");
        // console.log(dataURL)
      };
      img.src = url;
      return img;
    },
    doDownloadPdf(selected) {
      let date = moment().format("DD-MM-YYYY");
      const data = this.getBase64Image(this.$userLogin.company_logo);
      const doc = new jsPDF();
      console.log(doc.getFontList());
      // resp.data.map((v,i) => {
      let fontSize = 20;
      // if (i) {
      //   doc.addPage()
      // }
      doc.setFont("times").setFontSize(fontSize, "bold"); //.setFontStyle("bold");
      doc.addImage(data, "PNG", 10, 10, 20, 20);
      doc.text(27, 23, this.$userLogin.company_name);
      doc.line(10, 30, 200, 30); // horizontal line
      // doc.text(80, 40, 'TRANSFER ORDER'); //(text,x,y)
      doc.text("CASH DEPOSIT", 100, 40, { align: "center" }); //(text,x,y)
      //  Deposit Number : {{tr.DepositNumber}}<br>
      //             Method : {{methods[tr.Method]}}<br>
      //             <template v-if="tr.GiroBankID!=0">
      //               Customer : {{ '(' + tr.PersonCode + ') ' + tr.PersonName}} <br>
      //               {{methods[tr.Method]}} Bank : {{tr.GiroBankName}}<br>
      //               {{methods[tr.Method]}} Number : {{tr.GiroNumber}}<br>
      //               {{methods[tr.Method]}} Date : {{tr.GiroDate}}<br>
      fontSize = 12;

      doc.setFont("times").setFontSize(fontSize, "bold"); //.setFontStyle("bold");
      if (selected.CountPrint > 0) {
        doc.text(
          "Copy no " + selected.CountPrint + " ( printed :" + date + " )",
          100,
          48,
          { align: "center" }
        ); //(text,x,y)
      } else {
        doc.text("( printed :" + date + " )", 100, 48, { align: "center" }); //(text,x,y)
      }
      doc.text("Deposit Number", 10, 60); //(text,x,y)
      doc.text(": " + selected.DepositNumber, 40, 60); //(text,x,y)
      doc.text("Type", 10, 65); //(text,x,y)
      doc.text(": " + this.methods[selected.Method], 40, 65); //(text,x,y)
      doc.text("Value", 10, 70); //(text,x,y)
      doc.text(
        ": " +
          selected.DepositValue.toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        40,
        70
      ); //(text,x,y)

      doc.text("Deposit To", 10, 80); //(text,x,y)
      doc.setFont("times").setFontSize(fontSize); //.setFontStyle("bold");
      doc.text("Bank Name", 10, 100); //(text,x,y)
      doc.text(": " + selected.BankName, 40, 100); //(text,x,y)
      doc.text("Account Number", 10, 105); //(text,x,y)
      doc.text(": " + selected.AccountNumber, 40, 105); //(text,x,y)
      doc.text("Branch Name", 10, 110); //(text,x,y)
      doc.text(": " + selected.BankBranchName, 40, 110); //(text,x,y)

      // doc.setFontStyle('bold');
      doc.setFont("times").setFontSize(fontSize, "bold"); //.setFontStyle("bold");

      doc.text("Created By", 10, 250); //(text,x,y)
      doc.text(this.$userLogin.name, 10, 270); //(text,x,y)
      doc.text("Approved By", 80, 250); //(text,x,y)
      doc.text("Accepted By", 150, 250); //(text,x,y)
      // img, type, x, y, w, l

      doc.save("CASH-DEPOSIT" + moment().format("YYYY-MM-DD HHmm") + ".pdf");
    },
    handleShow(selected) {
      this.selectedData.class = "";
      selected.class = "highlight";
      this.selectedData = selected;
      this.detail = true;
      this.forceRender();
    },
    handleClose() {
      this.reloadData(this.params);
      this.selectedData.class = "";
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
    },
    reloadData(params) {
      this.params = params;
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.cashBank;
          this.responseData = r.data;
          this.responseData.length = r.data.cashBank.length;
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    getCustomerDepositByCustomer(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/cash-bank/data-table", {
            params: {
              status: 3,
              type: 2,
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.sort,
              sort: params.order,
              person_id: this.selectedData.PersonID,
              is_export_excel: true,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    },

    async generateExcel(tr) {
      // Create a new workbook
      this.$vs.loading();
      this.selectedData = tr;
      console.log("selected", tr);
      try {
        const resp = await this.getCustomerDepositByCustomer(this.params);
        if (resp.code == 500) {
          this.$vs.loading.close();
          throw new Error(resp.message);
        } else if (resp.code == 200) {
          this.$vs.loading.close();
          const data = resp.data.cashBank;
          console.log(data);
          const wb = XLSX.utils.book_new();
          var ws = XLSX.utils.aoa_to_sheet([]);

          if (!ws["!merges"]) ws["!merges"] = [];
          ws["!merges"].push(XLSX.utils.decode_range("A1:B2"));
          if (!ws["!cols"]) ws["!cols"] = [];

          /* create column metadata object if it does not exist */
          for (let COL_INDEX = 0; COL_INDEX < 7; COL_INDEX++) {
            if (!ws["!cols"][COL_INDEX]) {
              ws["!cols"][COL_INDEX] = { wpx: 150 };
            }
          }

          // Set the range of the worksheet
          // ws["!ref"] = "A1:C4";
          XLSX.utils.sheet_add_aoa(ws, [["Customer Deposit"]], {
            origin: "A1",
          });
          // Set raw text in a cell
          XLSX.utils.sheet_add_aoa(ws, [["Customer Name : "]], {
            origin: "A4",
          });

          XLSX.utils.sheet_add_aoa(ws, [[this.selectedData.PersonName]], {
            origin: "B4",
          });

          const header = [
            "Code",
            "Deposit Value",
            "Deposit Value Used",
            "Deposit Value Remaining",
            "Deposit Number",
            "Posting Date",
            "Deposit Date",
          ];

          // XLSX.utils.sheet_add_aoa(
          //   ws,
          //   header.map((v) => [v]), // Add an array of arrays to the worksheet
          //   { origin: "A6" }
          // );

          XLSX.utils.sheet_add_aoa(ws, [header], {
            origin: "A6",
          });

          for (let i = 0; i < data.length; i++) {
            const row = [
              data[i].Code,
              this.priceFormat(data[i].DepositValue),
              this.priceFormat(
                data[i].CashBankManagementLine.reduce(
                  (total, b) => total + b.PaymentValue,
                  0
                )
              ),
              this.priceFormat(
                data[i].DepositValue -
                  data[i].CashBankManagementLine.reduce(
                    (total, b) => total + b.PaymentValue,
                    0
                  )
              ),
              data[i].DepositNumber,
              this.globalDateFormat(data[i].Date),
              this.globalDateFormat(data[i].DatePayment),
            ];
            XLSX.utils.sheet_add_aoa(ws, [row], {
              origin: `A${7 + i}`,
            });
          }

          // Add the worksheet to the workbook
          XLSX.utils.book_append_sheet(wb, ws, "Customer Deposit");

          // Generate and download the Excel file
          // XLSX.writeFile(wb, "example.xlsx");

          const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

          // Function to convert the binary string to an ArrayBuffer

          // Create a Blob from the ArrayBuffer
          const blob = new Blob([this.s2ab(wbout)], {
            type: "application/octet-stream",
          });

          // Create a link element
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `Cash Deposit - ${this.selectedData.PersonName}.xlsx`;

          // Append the link to the document body
          document.body.appendChild(link);

          // Programmatically click the link to trigger the download
          link.click();

          // Remove the link from the document
          document.body.removeChild(link);
          // this.responseData = resp.data;
          // this.recordsTotal = resp.data.recordsTotal;
        } else {
          this.$vs.loading.close();
        }
        this.selectedData = {};
      } catch (e) {
        this.selectedData = {};
        console.log(e);
      }
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.highlight > td {
  background-color: #d0cdf0;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.vs-dialog {
  max-width: 1000px;
}
</style>
